.ais-ToggleRefinement-label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  font-size: 0.9rem;
}

.ais-ToggleRefinement-checkbox {
  font: inherit;
  margin-left: 1rem;
  position: relative;
  appearance: none;
  background: rgba(65, 66, 71, 0.08);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  height: 16px;
  min-width: 30px;
  transition: background 150ms ease-out;
  margin-top: 0.2rem;
}

.ais-ToggleRefinement-checkbox:checked::before {
  content: 'Yes';
  color: rgb(255, 126, 0);
}

.ais-ToggleRefinement-checkbox::before {
  align-items: center;
  color: rgba(33, 36, 61, 0.32);
  content: 'No';
  display: flex;
  font-size: 0.8rem;
  height: 16px;
  position: absolute;
  right: 38px;
}

.ais-ToggleRefinement-count {
  display: none;
}

.ais-ToggleRefinement-checkbox:checked {
  background: rgb(255, 126, 0);
}

.ais-ToggleRefinement-checkbox::after {
  background-image: linear-gradient(to top, #f5f5fa, #fff);
  border-radius: 100%;
  box-shadow:
    0 4px 11px 0 rgba(37, 44, 97, 0.15),
    0 2px 3px 0 rgba(93, 100, 148, 0.2);
  content: '';
  height: 16px;
  position: absolute;
  transition: transform 150ms ease-out;
  width: 16px;
}

.ais-ToggleRefinement-checkbox:checked::after {
  transform: translateX(100%);
}
